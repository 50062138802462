
import {
  communicationMapMutations,
  communicationMapState
} from "@/store/modules/communication";
import * as types from "@/store/mutation-types";
import * as selectOptions from "@/helpers/selectOptions";
import Vue from "vue";
import { getCompaniesForFilter } from "@/helpers/companies";
export default Vue.extend({
  components: {
    SelectUserUsers: () =>
      import("@/components/FormFields/SelectSearchUsers.vue"),
    SelectSearchAgencies: () =>
      import("@/components/FormFields/SelectSearchAgencies.vue")
  },
  name: "communication-recipients",
  data() {
    return {
      userRoleOptions: selectOptions.userRoleRecipientOptions
    };
  },
  methods: {
    ...communicationMapMutations({
      editField: types.SET_EDIT_FIELD
    }),
    changeTab(tab: string) {
      this.editField({ key: "recipientType", value: tab });
    }
  },
  computed: {
    ...communicationMapState(["editing", "makingApiRequest"]),
    companyOptions(): { label: string; value: string }[] {
      return getCompaniesForFilter(true);
    }
  }
});
